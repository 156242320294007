import _ from 'lodash';

import { ARTWORK_SECTION } from '../helpers/main';

const messageProcess = async ({
  item, user, history, dialogProcess, byeInfo, t, alert,
}) => {
  if (!user._id) {
    alert.show(t('login_or_register', { ns: 'main' }), { type: 'warning' });
    history.push('/login');
    return false;
  }
  if (user._id === item.owner_id) {
    alert.show(t('you_owner', { ns: 'alerts' }), { type: 'warning' });
    return false;
  }
  try {
    await dialogProcess({
      variables: {
        companion_id: item.owner_id,
      },
      fetchPolicy: 'no-cache',
    }).then(async (response) => {
      if (_.get(response, 'data.createDialog._id')) {
        await byeInfo({
          variables: {
            user_id: item.owner_id,
            item_id: item._id,
            dialog_id: _.get(response, 'data.createDialog._id'),
            model: ARTWORK_SECTION,
          },
        });
        history.push({
          pathname: `/account/messages/${_.get(response, 'data.createDialog._id')}`,
          state: { item, pay_request: true },
        });
      }
    });
    return true;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    return false;
  }
};

export default messageProcess;
